import React from 'react';
import Button from './Button';

const SkipLink = () => {
  return (
    <Button
      kind="primary"
      onClick={() => {
        const mainContent = document.getElementById('main-content');
        mainContent?.focus();

        mainContent?.getBoundingClientRect();

        window.scrollTo({
          top: mainContent?.getBoundingClientRect().top || 0,
          behavior: 'smooth'
        });
      }}
      className="skip-to-main-button"
    >
      Skip to main content
    </Button>
  );
};

export default SkipLink;
