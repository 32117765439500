import React, { LegacyRef } from 'react';
import classnames from 'classnames';

import Svg from '../components/Svg';

type FormCheckboxProps = {
  label: string | React.ReactNode;
  name: string;
  error?: string;
  size?: 'small' | 'default';
};

const FormCheckbox: React.FC<FormCheckboxProps> = React.forwardRef(
  ({ label, name, error, size = 'default', ...field }, ref) => {
    return (
      <>
        <div
          className={classnames({
            'form-checkbox__container': true,
            'form-checkbox__container--error': error,
            [`form-checkbox__container--size-${size}`]: size
          })}
        >
          <input
            id={name}
            className="form-checkbox__input"
            type="checkbox"
            name={name}
            ref={ref as LegacyRef<HTMLInputElement> | undefined}
            {...field}
          />

          <div className="form-checkbox__icon">
            <Svg content="check" />
          </div>

          <label className="form-checkbox__label" htmlFor={name}>
            {label}
          </label>
        </div>
        {error && <span className="form-checkbox__error">{error}</span>}
      </>
    );
  }
);

export default FormCheckbox;
