import React, { useState } from 'react';
import Counter from '../../../components/Counter';

import type { Product, SubProduct, BookingItem } from '../../../contexts/data';

import SubItemRow from './OptionalAddonSubRow';

type OptionalAddonProps = {
  addon: Product;
  onSubProductChange: (quantity: number, addon: Product, subProduct: SubProduct) => void;
  selectedSubProducts: BookingItem[];
};

const OptionalAddonRow = ({
  addon,
  onSubProductChange,
  selectedSubProducts
}: OptionalAddonProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const getSubProductsByParentId = (parentId: string): BookingItem[] => {
    return selectedSubProducts.filter(
      selectedSubProduct => selectedSubProduct.parentId === parentId
    );
  };

  const getPricesSum = (parentId: string) => {
    let sum = 0;

    const filteredSubProducts = getSubProductsByParentId(parentId);

    if (addon.products.length === 1 && !filteredSubProducts.length)
      return addon.products[0].cost.toFixed(2);

    filteredSubProducts.forEach(subProduct => {
      sum += subProduct.quantity * subProduct.cost;
    });

    return sum.toFixed(2);
  };

  return (
    <div className="add-ons__party-item-row">
      <div className="party-item-row__header">
        {addon.imageUrl && (
          <div className="image-wrapper">
            <img className="party-item-row__image" src={addon.imageUrl} alt="Addon" />
          </div>
        )}
        <div className={`text-wrapper ${!addon.imageUrl ? 'no-image' : ''}`}>
          <h5 className="party-item-row__title">{addon.name}</h5>

          {(getSubProductsByParentId(addon.id).length > 0 || addon.products.length === 1) && (
            <h4 className="party-item-row__price">+ ${getPricesSum(addon.id)}</h4>
          )}

          {addon.products.length === 1 && (
            <Counter
              ariaLabel="Addon quantity"
              value={
                getSubProductsByParentId(addon.id).length
                  ? getSubProductsByParentId(addon.id)[0].quantity
                  : 0
              }
              minValue={0}
              onChange={count => onSubProductChange(count, addon, addon.products[0])}
              onInput={count => onSubProductChange(count, addon, addon.products[0])}
            />
          )}

          {addon.products.length > 1 && (
            <button
              className={`party-item-row__toggle-visibility ${
                isExpanded ? 'expanded' : 'collapsed'
              }`}
              onClick={toggleExpand}
            >
              {isExpanded ? 'HIDE OPTIONS' : 'VIEW OPTIONS'}
            </button>
          )}
        </div>
      </div>
      {isExpanded && (
        <div>
          {addon.products.map(subProduct => (
            <SubItemRow
              key={subProduct.id}
              selectedSubProduct={selectedSubProducts.find(
                selectedSubProduct => selectedSubProduct.productId === subProduct.id
              )}
              subProduct={subProduct}
              onSubProductChange={(quantity, subProduct) =>
                onSubProductChange(quantity, addon, subProduct)
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default OptionalAddonRow;
