import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import Svg from '../components/Svg';

export type Option = {
  value: string;
  label: string;
};

type SelectProps = {
  label: string;
  id: string;
  options: Option[];
  value: Option;
  onChange: (option: Option) => void;
  error?: string;
  contextLabel?: string;
};

const Dropdown = ({ label, id, options, value, onChange, error, contextLabel }: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const optionsRef = useRef<HTMLUListElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setSelectedIndex(options.findIndex(option => option.value === value.value));
    }
  };

  const handleOptionClick = (option: Option) => {
    onChange(option);
    setIsOpen(false);
    setSelectedIndex(options.indexOf(option));
    optionsRef.current?.focus();
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        if (selectedIndex < options.length - 1) {
          setSelectedIndex(selectedIndex + 1);
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (selectedIndex > 0) {
          setSelectedIndex(selectedIndex - 1);
        }
        break;
      case 'Enter':
        if (isOpen && selectedIndex >= 0 && selectedIndex < options.length) {
          handleOptionClick(options[selectedIndex]);
        } else {
          toggleDropdown();
        }
        break;
      case 'Escape':
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  const onNaviveChanged = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const option = options.find(option => option.value === ev.target.value);
    if (option) {
      handleOptionClick(option);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      optionsRef.current?.focus();
    }
  }, [isOpen]);

  return (
    <div
      ref={dropdownRef}
      className={classnames('custom-dropdown', { 'custom-dropdown--error': error })}
    >
      <label className="dropdown-label" id={`${id}-label`} htmlFor={id}>
        {label}
      </label>
      <div
        id={id}
        className={classnames('dropdown', { open: isOpen })}
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-controls={`${id}-listbox`}
        aria-label={contextLabel || label}
        aria-activedescendant={
          isOpen && selectedIndex >= 0 ? `${id}-option-${selectedIndex}` : undefined
        }
        tabIndex={0}
      >
        <div className="selected-option">{value.label}</div>
        <div className="dropdown-arrow" aria-hidden="true">
          <Svg content="chevron-down" />
        </div>
        <ul
          ref={optionsRef}
          id={`${id}-listbox`}
          className="dropdown-options"
          role="listbox"
          aria-label={`${label} options`}
          style={{ display: isOpen ? 'block' : 'none' }}
        >
          {options.map((option, index) => (
            <li
              key={option.value}
              id={`${id}-option-${index}`}
              className={classnames('dropdown-option', { selected: selectedIndex === index })}
              role="option"
              aria-selected={selectedIndex === index}
              onClick={() => handleOptionClick(option)}
              onKeyDown={e => e.preventDefault()}
              tabIndex={-1}
            >
              {option.label}
            </li>
          ))}
        </ul>
      </div>

      <div className="native-dropdown-wrapper">
        <select
          className="dropdown"
          name={label}
          id={id}
          onChange={onNaviveChanged}
          value={value.value}
        >
          {!value.value && <option value="">Select</option>}
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <div className="dropdown-arrow">
          <Svg content="chevron-down" />
        </div>
      </div>
      {error && <span className="dropdown-error">{error}</span>}
    </div>
  );
};

export default Dropdown;
