/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useState, useEffect } from 'react';
import type { ChangeEvent, LegacyRef } from 'react';
import classnames from 'classnames';
import { Email } from '@smastrom/react-email-autocomplete';

import { formatPhone } from '../helpers/input';

type FormFieldProps = {
  type:
    | 'text'
    | 'email'
    | 'password'
    | 'number'
    | 'tel'
    | 'date'
    | 'time'
    | 'datetime-local'
    | 'month'
    | 'week'
    | 'url'
    | 'search'
    | 'textarea'
    | 'color';
  label: string;
  name: string;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  error?: string;
  max?: number;
  onChange?: (value: string) => void;
  onBlur?: () => void;
};

const FormField: React.FC<FormFieldProps> = React.forwardRef(
  ({ label, name, type, placeholder, disabled, error, max, onChange, onBlur, ...field }, ref) => {
    const [length, setLength] = useState(0);
    const [emailValue, setEmailValue] = useState<string>(field.value || '');

    useEffect(() => {
      const calendarIcon = document.querySelector('.form-field__calendar-icon');
      const handleCalendarClick = () => {
        const closestContainer = calendarIcon?.closest('.form-field__container');
        if (!closestContainer) return;

        const dateInput = closestContainer.querySelector('input') as HTMLInputElement;
        if (dateInput) {
          dateInput.focus();
        }
      };

      calendarIcon?.addEventListener('click', handleCalendarClick);

      return () => {
        calendarIcon?.removeEventListener('click', handleCalendarClick);
      };
    }, []);

    type NativeEvent = {
      inputType: string;
    };

    const onInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
      let newValue = ev.target.value;

      if (type === 'tel') {
        newValue =
          (ev.nativeEvent as unknown as NativeEvent).inputType !== 'deleteContentBackward'
            ? formatPhone(ev.target.value)
            : ev.target.value;
      } else if (type === 'date') {
        // Safari-specific check
        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
          // Immediately prevent any digit sequence longer than 4
          const currentInput = ev.target.value;
          console.log(currentInput);
          const lastNumber = currentInput.match(/\d+$/)?.[0]; // Get the last sequence of digits

          if (lastNumber && lastNumber.length > 4) {
            ev.target.value = currentInput.slice(0, -1); // Remove the last digit
            return;
          }
        }

        // Then do the full date pattern check
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (!datePattern.test(newValue)) {
          // Only allow if the input is incomplete (still being typed)
          if (newValue.length >= 10) {
            return;
          }
        }
      }

      onChange?.(newValue);
    };

    const handleEmailChange = (newValue: string) => {
      setEmailValue(newValue);
      onChange?.(newValue);
    };

    return (
      <div
        className={classnames({
          'form-field__container': true,
          'form-field__container--error': error,
          'form-field__container--disabled': disabled
        })}
      >
        <label className="form-field__label" htmlFor={name}>
          {label}
        </label>

        {type === 'textarea' && (
          <textarea
            id={name}
            className="form-field__input"
            name={name}
            placeholder={placeholder}
            ref={ref as LegacyRef<HTMLTextAreaElement> | undefined}
            onChange={ev => {
              setLength(ev.target.value.length);
              onChange?.(ev.target.value);
            }}
            onBlur={onBlur}
            disabled={disabled}
            {...field}
          />
        )}
        {type === 'email' && (
          <Email
            id={name}
            className="form-field__input-email"
            name={name}
            placeholder={placeholder}
            value={emailValue}
            refineList={['gmail.com', 'hotmail.com', 'yahoo.com', 'icloud.com']}
            baseList={['gmail.com', 'hotmail.com', 'yahoo.com', 'icloud.com']}
            onChange={handleEmailChange}
            onBlur={onBlur}
            disabled={disabled}
          />
        )}
        {type !== 'email' && type !== 'textarea' && (
          <input
            id={name}
            className="form-field__input"
            type={type}
            name={name}
            placeholder={placeholder}
            ref={ref as LegacyRef<HTMLInputElement> | undefined}
            onChange={onInputChange}
            onBlur={onBlur}
            disabled={disabled}
            min={type === 'date' ? '1000-01-01' : undefined}
            max={type === 'date' ? '9999-12-31' : undefined}
            {...field}
          />
        )}

        {type === 'textarea' && max && (
          <span className="form-field__counter">
            {length}/{max}
          </span>
        )}

        {error && <span className="form-field__error">{error}</span>}

        {type === 'date' && <span className="form-field__calendar-icon"></span>}
      </div>
    );
  }
);

export default FormField;
