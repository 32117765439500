import React, { useContext, useEffect, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form';
import classNames from 'classnames';
import '@adyen/adyen-web/dist/adyen.css';

import { turnJsonToHumanReadableString } from '../../../helpers/humanReadable';

import type { IPaymentResult } from '@roller/ecom-payments';

import { DataContext } from '../../../contexts/data';
import type { DataContextValueType } from '../../../contexts/data';

import { StepsContext } from '../../../contexts/steps';
import type { StepsContextValueType } from '../../../contexts/steps';

import { ModalContext } from '../../../contexts/modal';
import type { ModalContextValueType } from '../../../contexts/modal';

import FormField from '../../../components/FormField';
import FormCheckbox from '../../../components/FormCheckbox';
import ErrorAlert from '../../../components/Alerts/Error';
import Alert from '../../../components/Alert';

import CheckoutDescription from './CheckoutDescription';
import CheckoutPrices from './CheckoutPrices';
import CheckoutButtons from './CheckoutButtons';

import API from '../../../api/api';
import { removeEmojis } from '../../../helpers/input';
import InfoMessage from '../../../components/Alerts/InfoMessage';

type FormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  accessibleAccommodations: boolean;
  extraInfo?: string;
  marketing: boolean;
};

type FormFields = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  accessibleAccommodations?: boolean;
  extraInfo?: string;
  marketing?: boolean;
};

type CheckoutProps = {
  onNext: () => void;
};

const Checkout = ({ onNext }: CheckoutProps) => {
  const {
    bookingData: [bookingData, setBookingData],
    setupPayment,
    selectedPackage,
    bookingItems: [bookingItems],
    externalId,
    paymentReady: [paymentReady, setPaymentReady],
    partyNameString
  } = useContext(DataContext) as DataContextValueType;

  const {
    activeStep,
    stepWillChange: [stepWillChange],
    goToStep
  } = useContext(StepsContext) as StepsContextValueType;

  const {
    showLoading,
    hideLoading,
    open: openModal,
    close: closeModal
  } = useContext(ModalContext) as ModalContextValueType;

  const [nextDisabled, setNextDisabled] = useState(true);
  const [paymentError, setPaymentError] = useState(false);
  const [needsAccessibleAccommodations, setNeedsAccessibleAccommodations] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const startPaymentSettings = () => {
    const handlers = {
      onReady: () => {
        setPaymentReady(true);
        hideLoading();
      },
      onPaymentReceived: () => console.log('onPaymentReceived'),
      onPaymentCompleted: (result: IPaymentResult) => {
        if (result.message === 'Authorised') {
          onNext();
        }

        if (result.message === 'Refused') {
          setPaymentError(true);
        }
      }
    };
    setupPayment(handlers);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      accessibleAccommodations: false,
      marketing: false
    }
  });

  const getPackageItem = () => {
    return {
      productId: selectedPackage?.products[0].id || '',
      quantity: bookingData.extra.jumpersNumber,
      bookingDate: bookingData.extra.bookingDate,
      startTime: bookingData.extra.startTime,
      name: selectedPackage?.products[0].name || '',
      parentName: selectedPackage?.name || '',
      cost: selectedPackage?.products[0].cost || 0,
      tax: selectedPackage?.products[0].tax || 0,
      parentId: selectedPackage?.id || '',
      partyPackageInclusions: bookingItems.filter(bookingItem => bookingItem.isIncluded)
    };
  };

  const getBookingPayload = (data: FormValues, capacityReservationId: string | undefined) => {
    const customer = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: bookingData.customer.email,
      phone: data.phone,
      acceptMarketing: !!data.marketing
    };

    const packageItem = getPackageItem();

    const filteredBookingItems = bookingItems.filter(bookingItem => !bookingItem.isIncluded);

    const items = [...filteredBookingItems, packageItem];

    const payload = {
      externalId,
      customer,
      comments: getComments(data),
      name: partyNameString,
      capacityReservationId,
      discounts: bookingData.extra.discounts?.length
        ? [{ code: bookingData.extra.discounts[0].code }]
        : [],
      items,
      sendConfirmations: true
    };

    return payload;
  };

  const getComments = (data: FormValues): string => {
    const comments = {
      accessibleAccommodations: data.accessibleAccommodations ?? false,
      extraInfo: data.extraInfo ?? '',
      guestOfHonorDob: bookingData.extra.guestOfHonorDob,
      secondGuestOfHonorDob: bookingData.extra.secondGuestOfHonorDob,
      thirdGuestOfHonorDob: bookingData.extra.thirdGuestOfHonorDob,
      nonJumpersNumber: bookingData.extra.nonJumpersNumber,
      Notes: ''
    };

    return turnJsonToHumanReadableString(comments);
  };

  const createDraftBooking = async (data: FormValues) => {
    showLoading();

    try {
      if (bookingData.extra.capacityReservationId) {
        await API.deleteCapacityReservation(bookingData.extra.capacityReservationId);
      }

      const capacityReservationPayload = getBookingPayload(data, undefined);
      const capacityReservationRes = await API.capacityReservation(capacityReservationPayload);

      if (capacityReservationRes.errors) {
        openModal(
          <ErrorAlert
            title="Session is not available"
            description={capacityReservationRes.errors[0].message}
            onCloseModal={closeModal}
            buttons={['exitBooking']}
          />,
          false
        );
        return;
      }

      const draftBookingPayload = getBookingPayload(data, capacityReservationRes.uniqueId);
      if (draftBookingPayload.name)
        draftBookingPayload.name = removeEmojis(draftBookingPayload.name);

      const draftBookingResponse = await API.createDraftBooking(draftBookingPayload);

      if (draftBookingResponse.errors) {
        hideLoading();
        openModal(
          <ErrorAlert
            title="Error"
            description={draftBookingResponse.errors[0].message}
            onCloseModal={closeModal}
            buttons={['exitBooking', 'close']}
          />
        );
        return;
      }

      if (draftBookingResponse) {
        setBookingData({
          ...bookingData,
          extra: {
            ...bookingData.extra,
            paymentJwt: draftBookingResponse.paymentJwt,
            bookingUniqueId: draftBookingResponse.uniqueId,
            capacityReservationId: capacityReservationRes.uniqueId,
            comments: getComments(data)
          },
          customer: {
            ...bookingData.customer,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            acceptMarketing: !!data.marketing
          }
        });
      }

      return draftBookingResponse;
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    }
  };

  const publishBookingNoPayment = async () => {
    try {
      const publishBookingResponse = await API.publishBooking(
        bookingData.extra.bookingUniqueId || ''
      );
      if (publishBookingResponse.errors) {
        hideLoading();
        return;
      }
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    } finally {
      hideLoading();
    }

    onNext();
  };

  const onSubmit: SubmitHandler<FormValues> = async data => {
    await createDraftBooking(data);
  };

  const handleStepChange = () => {
    if (paymentReady) return;
    setPaymentReady(false);
    resetPayment();
    stepWillChange.stepValue && goToStep(stepWillChange.stepValue);
  };

  const checkEmptyFields = (data: FormFields) => {
    if (data.firstName && data.lastName && data.phone) {
      setNextDisabled(false);
      return;
    }
    setNextDisabled(true);
  };

  const resetPayment = () => {
    setPaymentReady(false);
    setPaymentError(false);
    window.scrollTo(0, 0);
    const paymentContainer = document.querySelector('#payment-container');
    const billingContainer = document.querySelector('.checkout__row--billing');

    if (paymentContainer) {
      paymentContainer.remove();
    }

    if (billingContainer) {
      const newDiv = document.createElement('div');
      newDiv.setAttribute('id', 'payment-container');
      billingContainer.appendChild(newDiv);
    }
  };

  const handleAccessibleAccommodationsChange = (checked: boolean) => {
    if (checked) {
      openModal(
        <InfoMessage
          title="Accessible Accommodations"
          description={`We want to make sure your party is fun for all your Guests!
Your selected party date and time may need to be adjusted so we
can fully accommodate your needs. We’ll reach out to you to
confirm your party details within 24 hours of booking. If you would
like to speak to someone immediately, give us a call at ${bookingData.extra.parkPhoneNumber}.`}
          onCloseModal={closeModal}
          buttons={['close']}
        />
      );
    }
  };

  watch(data => {
    if (data.accessibleAccommodations && !needsAccessibleAccommodations)
      setNeedsAccessibleAccommodations(true);
    else if (!data.accessibleAccommodations && needsAccessibleAccommodations)
      setNeedsAccessibleAccommodations(false);
    checkEmptyFields(data);
  });

  useEffect(() => {
    if (needsAccessibleAccommodations)
      handleAccessibleAccommodationsChange(needsAccessibleAccommodations);
  }, [needsAccessibleAccommodations]);

  useEffect(() => {
    if (
      bookingData.extra.paymentJwt &&
      bookingData.extra.prices?.total &&
      bookingData.extra.prices.total > 0
    ) {
      startPaymentSettings();
      return;
    }

    if (bookingData.extra.prices?.total === 0) {
      publishBookingNoPayment();
    }
  }, [bookingData.extra.paymentJwt]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'checkout') {
      resetPayment();
    }
  }, [activeStep]);

  useEffect(() => {
    if (activeStep && activeStep.value === 'checkout' && stepWillChange.willChange) {
      handleStepChange();
    }
  }, [stepWillChange.willChange]);

  return (
    <>
      <CheckoutDescription />

      <div className="checkout container">
        <form ref={formRef} className="checkout__form" noValidate onSubmit={handleSubmit(onSubmit)}>
          <div className="checkout__columns">
            <div className="checkout__column-one">
              <div
                className={classNames('checkout__row checkout__row--contact', {
                  ['checkout__row--finished']: paymentReady
                })}
              >
                <h2 className="checkout__subtitle">Contact Information</h2>

                <div className="checkout__fields-columns">
                  <Controller
                    name="firstName"
                    control={control}
                    rules={{
                      required: 'Please enter your first name.'
                    }}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        label="Your First Name*"
                        error={errors.firstName?.message}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    rules={{
                      required: 'Please enter your last name.'
                    }}
                    render={({ field }) => (
                      <FormField
                        type="text"
                        label="Your Last Name*"
                        error={errors.lastName?.message}
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="phone"
                    control={control}
                    rules={{
                      required: 'Please enter a valid phone number.',
                      pattern: {
                        value: /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/i,
                        message: 'Please enter a valid phone number'
                      }
                    }}
                    render={({ field }) => (
                      <FormField
                        type="tel"
                        label="Your Phone Number*"
                        error={errors.phone?.message}
                        {...field}
                      />
                    )}
                  />
                </div>

                <div className="checkout__fields-row">
                  <Controller
                    name="accessibleAccommodations"
                    control={control}
                    render={({ field }) => (
                      <FormCheckbox label="I need accessible accommodations." {...field} />
                    )}
                  />
                </div>

                <div className="checkout__fields-row">
                  <Controller
                    name="extraInfo"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 300,
                        message: 'Please enter a maximum of 300 characters.'
                      }
                    }}
                    render={({ field }) => (
                      <FormField
                        type="textarea"
                        max={300}
                        label={`Anything else we should know about ${partyNameString}?`}
                        error={errors.extraInfo?.message}
                        {...field}
                      />
                    )}
                  />
                </div>

                <div className="checkout__fields-row">
                  <Controller
                    name="marketing"
                    control={control}
                    render={({ field }) => (
                      <FormCheckbox
                        size="small"
                        label={
                          <span>
                            By checking this box, you consent to receive text messages and emails
                            from CircusTrix Holdings, LLC (d/b/a “Sky Zone”) and its agents using an
                            auto dialer and email platform. You authorize Sky Zone to send you
                            periodic SMS messages and emails for marketing purposes. You may opt out
                            at any time by replying STOP from the mobile device receiving the text
                            messages, or by clicking “unsubscribe” in the footer of the email
                            message. Message and data rates may apply. Your consent to receive text
                            messages or emails is not required to make a purchase. Please read our{' '}
                            <a href="https://www.skyzone.com/privacy-policy/" target="_blank">
                              Privacy Policy
                            </a>{' '}
                            and{' '}
                            <a href="https://www.skyzone.com/terms-of-service/" target="_blank">
                              Terms of Service
                            </a>{' '}
                            for more information.
                          </span>
                        }
                        {...field}
                      />
                    )}
                  />
                </div>
              </div>

              <div
                className={classNames('checkout__row checkout__row--billing', {
                  ['checkout__row--hidden']: !paymentReady
                })}
              >
                <h2 className="checkout__subtitle">Billing Information</h2>
                <Alert
                  type="error"
                  className={classNames('checkout__alert', {
                    'checkout__alert--show': paymentError
                  })}
                >
                  There was an error with your payment
                </Alert>

                <div id="payment-container"></div>
              </div>
            </div>

            <div className="checkout__column-two">
              <CheckoutPrices />
            </div>
          </div>

          <CheckoutButtons nextDisabled={nextDisabled} />
        </form>
      </div>
    </>
  );
};

export default Checkout;
