import React, { useContext } from 'react';
import type { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import classnames from 'classnames';

import { ModalContext } from '../contexts/modal';
import type { ModalContextValueType } from '../contexts/modal';

import { StepsContext } from '../contexts/steps';
import type { StepsContextValueType } from '../contexts/steps';

import { DataContext } from '../contexts/data';
import type { DataContextValueType } from '../contexts/data';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Modal from '../components/Modal';
import Loading from '../components/Loading';

import FixedIconsContainer from '../modules/FixedIconsContainer';
import Faq from '../modules/Faq';
import ContactRow from '../modules/ContactRow';
import SkipToMainButton from '../components/SkipToMainButton';

function BasicLayout() {
  const {
    steps: [stepsData]
  } = useContext(StepsContext) as StepsContextValueType;

  const {
    isShowing: isModalShowing,
    isLoadingShowing,
    close: closeModal,
    content: modalConent,
    closeOnClickOutside
  } = useContext(ModalContext) as ModalContextValueType;

  const { isEditMode } = useContext(DataContext) as DataContextValueType;

  return (
    <>
      <SkipToMainButton />
      <div
        className={classnames({
          app: true,
          'has-steps': true,
          'has-subheader':
            stepsData[3].done &&
            !isEditMode &&
            !stepsData.find(step => step.value === 'checkout')?.done &&
            window.location.pathname !== '/confirmation'
        })}
      >
        <Header />
        <main id="main-content" tabIndex={-1} role="main">
          <FixedIconsContainer />
          <Outlet />
          <ContactRow />
          <Faq />
        </main>
        <Footer />
        <Modal
          isShowing={isModalShowing}
          content={modalConent as ReactElement}
          onCloseModal={closeModal}
          closeOnClickOutside={closeOnClickOutside}
        />
        <Loading isShowing={isLoadingShowing} />
      </div>
    </>
  );
}

export default BasicLayout;
