import React, { useContext, useState } from 'react';

import { DataContext } from '../../contexts/data';
import type { DataContextValueType } from '../../contexts/data';

import { ModalContext } from '../../contexts/modal';
import type { ModalContextValueType } from '../../contexts/modal';

import Button from '../Button';
import ErrorAlert from './Error';
import Svg from '../Svg';

import API from '../../api/api';

type TimeOverAlertProps = {
  onCloseModal: () => void;
};

function TimeOverAlert({ onCloseModal }: TimeOverAlertProps) {
  const {
    bookingData: [bookingData, setBookingData],
    paymentReady: [paymentReady],
    bookingItems: [bookingItems],
    isEditMode,
    selectedPackage
  } = useContext(DataContext) as DataContextValueType;

  const {
    showLoading,
    hideLoading,
    open: openModal,
    close: closeModal
  } = useContext(ModalContext) as ModalContextValueType;

  const [errorMessage, setErrorMessage] = useState('');

  const handleExitBooking = () => {
    window.location.href = document.referrer || 'https://www.skyzone.com/parties/';
  };

  const getPackageItem = () => {
    return {
      productId: selectedPackage?.products[0].id || '',
      quantity: bookingData.extra.jumpersNumber,
      bookingDate: bookingData.extra.bookingDate,
      startTime: bookingData.extra.startTime,
      name: selectedPackage?.products[0].name || '',
      parentName: selectedPackage?.name || '',
      cost: selectedPackage?.products[0].cost || 0,
      tax: selectedPackage?.products[0].tax || 0,
      parentId: selectedPackage?.id || '',
      partyPackageInclusions: bookingItems.filter(bookingItem => bookingItem.isIncluded)
    };
  };

  const handleChatClick = () => {
    //check if the brandembassy function is defined
    if (typeof brandembassy === 'function') brandembassy('openChatWindow');
  };

  const getBookingPayload = () => {
    const packageItem = getPackageItem();
    const filteredBookingItems = bookingItems.filter(bookingItem => !bookingItem.isIncluded);
    const items = [...filteredBookingItems, packageItem];
    const payload = {
      items
    };
    return payload;
  };

  const handleReload = async () => {
    showLoading();

    if (bookingData.extra.capacityReservationId && !isEditMode) {
      await API.deleteCapacityReservation(bookingData.extra.capacityReservationId);
    }

    if (isEditMode) {
      window.location.href = `${window.location.origin}/review${window.location.search}`;
      return;
    }

    window.location.reload();
  };

  const reStartSession = async () => {
    showLoading();

    try {
      if (bookingData.extra.capacityReservationId) {
        await API.deleteCapacityReservation(bookingData.extra.capacityReservationId);
      }

      const capacityReservationPayload = getBookingPayload();
      const capacityReservationRes = await API.capacityReservation(capacityReservationPayload);

      setBookingData({
        ...bookingData,
        extra: {
          ...bookingData.extra,
          capacityReservationId: capacityReservationRes.uniqueId
        }
      });

      if (capacityReservationRes.errors) {
        setErrorMessage(capacityReservationRes.errors[0].message);
        return;
      }

      onCloseModal();
    } catch (error) {
      openModal(
        <ErrorAlert
          title="Unknown error"
          description="Our server is returning an unknown error, please try again later"
          onCloseModal={closeModal}
          buttons={['exitBooking', 'reload']}
        />,
        false
      );
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  return (
    <div className="time-over-alert">
      <header className="time-over-alert__header">
        <h3 className="time-over-alert__header__title">Session Time Out</h3>
        <button
          className="time-over-alert__close-btn"
          aria-label="close"
          onClick={paymentReady ? reStartSession : handleReload}
        >
          <img src="/icons/close.svg" alt="close" />
        </button>
      </header>
      <div className="time-over-alert__top">
        <div className="time-over-alert__title">Questions?</div>
        <div className="time-over-alert__text">
          Speak to a helpful party hosts who can walk you through everything you need to know
        </div>
      </div>
      <div className="time-over-alert__bottom">
        <div className="time-over-alert__chat-grid">
          <div className="time-over-alert__chat-block">
            <button
              className="time-over-alert__action-trigger contact-row__link"
              onClick={handleChatClick}
            >
              <svg
                width="116"
                height="116"
                viewBox="0 0 116 116"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="58" cy="58" r="58" fill="#EAECF8" />
                <g clip-path="url(#clip0_5531_8)">
                  <path
                    d="M38.3699 51.604H50.5707C51.6937 51.604 52.6041 50.6936 52.6041 49.5706V47.5371L57.4844 51.1973C58.1014 51.66 58.9259 51.7333 59.6139 51.3894C60.3028 51.0448 60.738 50.3408 60.738 49.5706V37.3698C60.738 36.5995 60.3028 35.8955 59.6139 35.5509C58.9251 35.2066 58.1004 35.2809 57.4844 35.743L52.6041 39.4032V37.3698C52.6041 36.2468 51.6937 35.3363 50.5707 35.3363H38.3699C37.2469 35.3363 36.3364 36.2468 36.3364 37.3698V49.5706C36.3364 50.6936 37.2469 51.604 38.3699 51.604ZM56.6711 41.4367V45.5036L53.9598 43.4702L56.6711 41.4367ZM40.4034 39.4032H48.5372V47.5371H40.4034V39.4032Z"
                    fill="#FE5000"
                  />
                  <path
                    d="M32.2694 63.6045V69.9052C32.2694 70.7277 32.7649 71.4691 33.5247 71.7839C34.2846 72.0986 35.1592 71.9246 35.7408 71.3431L43.2791 63.8035H48.7429C48.6071 64.4714 48.5371 65.1526 48.5371 65.8383V73.9721C48.5371 79.5784 53.0982 84.1395 58.7044 84.1395H74.12L81.6563 91.8014C82.238 92.3929 83.116 92.5686 83.8764 92.2573C84.6403 91.9446 85.1395 91.201 85.1395 90.3754V83.9371C89.8655 82.988 93.4089 78.8272 93.4089 73.9721V65.8383C93.4089 60.2181 88.7273 55.671 83.106 55.671H72.7341C72.868 55.0138 72.9387 54.3338 72.9387 53.6375V33.1673C72.9387 27.5611 68.3776 23 62.7714 23H34.3029C28.6827 23 24 27.546 24 33.1673V53.6375C24 58.6303 27.6735 62.6864 32.2694 63.6045ZM83.106 59.7379C86.4863 59.7379 89.3419 62.5315 89.3419 65.8383V73.9721C89.3419 77.3169 86.5214 80.0725 83.106 80.0725C81.983 80.0725 81.0725 80.983 81.0725 82.106V85.4082L76.4219 80.68C76.0396 80.2915 75.5172 80.0725 74.9721 80.0725H58.7044C55.3407 80.0725 52.604 77.3359 52.604 73.9721V65.8383C52.604 65.142 52.7247 64.4543 52.9537 63.8048H62.7714C66.0905 63.8048 69.0428 62.2057 70.8997 59.7379H83.106ZM28.0669 33.1673C28.0669 29.8605 30.9226 27.0669 34.3029 27.0669H62.7714C66.1351 27.0669 68.8718 29.8036 68.8718 33.1673V53.6375C68.8718 57.0013 66.1351 59.7379 62.7714 59.7379H42.4367C41.8975 59.7379 41.3803 59.9521 40.9989 60.3334L36.3363 64.996V61.7714C36.3363 60.6483 35.4259 59.7379 34.3029 59.7379C30.9226 59.7379 28.0669 56.9443 28.0669 53.6375V33.1673Z"
                    fill="#2D3FB8"
                  />
                  <path
                    d="M62.7713 71.9386C63.8943 71.9386 64.8047 71.0282 64.8047 69.9052C64.8047 68.7821 63.8943 67.8717 62.7713 67.8717C61.6482 67.8717 60.7378 68.7821 60.7378 69.9052C60.7378 71.0282 61.6482 71.9386 62.7713 71.9386Z"
                    fill="#FE5000"
                  />
                  <path
                    d="M70.9053 71.9386C72.0283 71.9386 72.9388 71.0282 72.9388 69.9052C72.9388 68.7821 72.0283 67.8717 70.9053 67.8717C69.7822 67.8717 68.8718 68.7821 68.8718 69.9052C68.8718 71.0282 69.7822 71.9386 70.9053 71.9386Z"
                    fill="#FE5000"
                  />
                  <path
                    d="M79.0391 71.9386C80.1621 71.9386 81.0725 71.0282 81.0725 69.9052C81.0725 68.7821 80.1621 67.8717 79.0391 67.8717C77.916 67.8717 77.0056 68.7821 77.0056 69.9052C77.0056 71.0282 77.916 71.9386 79.0391 71.9386Z"
                    fill="#FE5000"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5531_8">
                    <rect
                      width="69.4089"
                      height="69.4089"
                      fill="white"
                      transform="translate(24 23)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>live chat</span>
            </button>
          </div>
          <div className="time-over-alert__chat-block">
            <a
              href={`tel:${bookingData.extra.parkPhoneNumber}`}
              className="time-over-alert__action-trigger contact-row__link"
            >
              <svg
                width="116"
                height="116"
                viewBox="0 0 116 116"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="58" cy="58" r="58" fill="#EAECF8" />
                <g clip-path="url(#clip0_5531_7)">
                  <path
                    d="M68.5496 27.2025H46.8594C42.7481 27.2025 39.4033 30.5473 39.4033 34.6585V80.7504C39.4033 84.8616 42.7481 88.2064 46.8594 88.2064H68.5496C72.6609 88.2064 76.0057 84.8616 76.0057 80.7504V34.6585C76.0057 30.5473 72.6609 27.2025 68.5496 27.2025ZM71.9387 80.7504C71.9387 82.6191 70.4184 84.1395 68.5496 84.1395H46.8594C44.9906 84.1395 43.4702 82.6191 43.4702 80.7504V34.6585C43.4702 32.7898 44.9906 31.2694 46.8594 31.2694H68.5496C70.4184 31.2694 71.9387 32.7898 71.9387 34.6585V80.7504Z"
                    fill="#2D3FB8"
                  />
                  <path
                    d="M60.4157 76.0056H54.9932C53.8702 76.0056 52.9597 76.9161 52.9597 78.0391C52.9597 79.1621 53.8702 80.0725 54.9932 80.0725H60.4157C61.5388 80.0725 62.4492 79.1621 62.4492 78.0391C62.4492 76.9161 61.5388 76.0056 60.4157 76.0056Z"
                    fill="#2D3FB8"
                  />
                  <path
                    d="M60.4157 35.3364H54.9932C53.8702 35.3364 52.9597 36.2469 52.9597 37.3699C52.9597 38.4929 53.8702 39.4034 54.9932 39.4034H60.4157C61.5388 39.4034 62.4492 38.4929 62.4492 37.3699C62.4492 36.2469 61.5388 35.3364 60.4157 35.3364Z"
                    fill="#2D3FB8"
                  />
                  <path
                    d="M89.4581 44.5425C88.9272 43.5529 87.6947 43.1809 86.7051 43.7118C85.7154 44.2426 85.3433 45.4752 85.8742 46.4648V46.4649C87.4656 49.4316 88.342 53.4226 88.342 57.7025C88.342 61.9824 87.4649 65.9748 85.8723 68.9439V68.9441C85.3414 69.9337 85.7134 71.1662 86.703 71.6971H86.7032C87.6928 72.228 88.9253 71.856 89.4562 70.8664C91.3603 67.3165 92.4089 62.6414 92.4089 57.7025C92.4089 52.7637 91.361 48.0899 89.4581 44.5425Z"
                    fill="#FE5000"
                  />
                  <path
                    d="M79.8367 47.8296C78.8751 48.4098 78.5661 49.6597 79.1463 50.6213C80.2681 52.4801 80.8858 54.9952 80.8858 57.7033C80.8858 60.4113 80.2675 62.9272 79.1448 64.7877C78.5646 65.7493 78.8736 66.999 79.8352 67.5794C80.7968 68.1597 82.0467 67.8505 82.6269 66.889C84.1268 64.4034 84.9528 61.1412 84.9528 57.7033C84.9528 54.2654 84.1273 51.004 82.6284 48.52C82.0482 47.5585 80.7982 47.2494 79.8367 47.8296Z"
                    fill="#FE5000"
                  />
                  <path
                    d="M29.5366 68.9441C27.944 65.9748 27.0669 61.9826 27.0669 57.7025C27.0669 53.4225 27.9433 49.4316 29.5347 46.4648C30.0656 45.4752 29.6936 44.2425 28.704 43.7116C27.7144 43.1808 26.4817 43.5528 25.9508 44.5424C24.0479 48.09 23 52.7637 23 57.7025C23 62.6414 24.0486 67.3165 25.9529 70.8664C26.4837 71.856 27.7163 72.228 28.7059 71.6971C29.6955 71.1664 30.0675 69.9337 29.5366 68.9441Z"
                    fill="#FE5000"
                  />
                  <path
                    d="M34.523 57.7033C34.523 54.9952 35.1407 52.4803 36.2625 50.6213C36.8428 49.6597 36.5337 48.4099 35.5721 47.8296C34.6106 47.2494 33.3607 47.5585 32.7804 48.52C31.2815 51.004 30.4561 54.2652 30.4561 57.7033C30.4561 61.1413 31.282 64.4034 32.7819 66.889C33.3621 67.8505 34.612 68.1596 35.5736 67.5794C36.5352 66.9992 36.8442 65.7493 36.264 64.7877C35.1413 62.9272 34.523 60.4112 34.523 57.7033Z"
                    fill="#FE5000"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5531_7">
                    <rect
                      width="69.4089"
                      height="69.4089"
                      fill="white"
                      transform="translate(23 23)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>call us</span>
            </a>
          </div>
        </div>
        <div className="time-over-alert__buttons-container">
          {!errorMessage && !paymentReady && (
            <Button kind="secondary" onClick={reStartSession}>
              Continue Booking My Party
            </Button>
          )}
          {errorMessage ||
            (paymentReady && (
              <Button kind="secondary" onClick={handleReload}>
                Restart booking
              </Button>
            ))}
          <Button
            onClick={handleExitBooking}
            kind="ghost"
            leftIcon={<Svg content="double-chevron-left" />}
          >
            Exit Booking
          </Button>
        </div>
      </div>
    </div>
  );
}

export default TimeOverAlert;
