import React from 'react';
import Counter from '../../../components/Counter';

type GuestDetailsQuantityProps = {
  jumpersNumber: number;
  nonJumpersNumber: number;
  handleJumpersChange: (newValue: number) => void;
  handleNonJumpersChange: (newValue: number) => void;
  isOverVenueLimit: boolean;
};

const GuestDetailsQuantity = ({
  jumpersNumber,
  nonJumpersNumber,
  handleJumpersChange,
  handleNonJumpersChange,
  isOverVenueLimit
}: GuestDetailsQuantityProps) => {
  return (
    <div className="guest-details__number-of-guests">
      <h4 className="guest-details__number-of-guests-heading">Estimated Number of Guests</h4>
      <p className="guest-details__number-of-guests-subheading">
        Don't worry, you can change this later. For now, plan for the maximum number of guests.
      </p>
      <div className="guest-details__number-of-guests-calculator">
        <div className="guest-details_calculator-text-container">
          <h6 className="guest-details__calculator-text-label">Estimated number of jumpers</h6>
          <span className="guest-details__calculator-text-sublabel">
            10 jumpers minimum required. Please include parent/adult jumpers.
          </span>
        </div>
        <Counter
          ariaLabel="Estimated number of jumpers"
          key={`jumpers-${jumpersNumber}`}
          value={jumpersNumber}
          minValue={10}
          onInput={handleJumpersChange}
          onChange={handleJumpersChange}
          disableAdd={isOverVenueLimit}
        />
      </div>
      <div className="guest-details__number-of-guests-calculator">
        <div className="guest-details_calculator-text-container">
          <h6 className="guest-details__calculator-text-label">Estimated number of non-jumpers</h6>
          <span className="guest-details__calculator-text-sublabel">
            Parents/guests not jumping are free
          </span>
        </div>
        <Counter
          ariaLabel="Estimated number of non-jumpers"
          key={`non-jumpers-${nonJumpersNumber}`}
          value={nonJumpersNumber}
          onInput={handleNonJumpersChange}
          onChange={handleNonJumpersChange}
          minValue={0}
          disableAdd={isOverVenueLimit}
        />
      </div>
    </div>
  );
};

export default GuestDetailsQuantity;
