import React, { useContext, useEffect } from 'react';

import { DataContext } from '../contexts/data';
import type { DataContextValueType } from '../contexts/data';

import Svg from '../components/Svg';

function ConfirmationPage() {
  const {
    bookingData: [bookingData],
    bookingItems: [bookingItems],
    selectedPackage,
    venueInfo,
    partyNameEl
  } = useContext(DataContext) as DataContextValueType;

  const formatTime = (time: string) => {
    const timeArray = time.split(':');
    const hours = Number(timeArray[0]);
    const minutes = Number(timeArray[1]);
    let timeValue;

    if (hours > 0 && hours <= 12) {
      timeValue = '' + hours;
    } else if (hours > 12) {
      timeValue = '' + (hours - 12);
    } else if (hours === 0) {
      timeValue = '12';
    }

    timeValue += minutes < 10 ? ':0' + minutes : ':' + minutes;
    timeValue += hours >= 12 ? ' pm' : ' am';

    return timeValue;
  };

  const formatDate = (date: string) => {
    const splitedBookingDate = date.split('-');
    const formatedBookingDate = `${splitedBookingDate[1]}/${splitedBookingDate[2]}/${splitedBookingDate[0]}`;

    return formatedBookingDate;
  };

  const trackConfirmation = () => {
    window.dataLayer = window.dataLayer || [];

    const coupon =
      bookingData.extra.discounts && bookingData.extra.discounts.length > 0
        ? bookingData.extra.discounts[0].code
        : undefined;
    const packageItem = {
      item_id: selectedPackage?.products[0].id,
      item_name: selectedPackage?.name,
      item_brand: 'partypackage',
      item_variant: selectedPackage?.products[0].name,
      price: selectedPackage?.products[0].cost,
      quantity: bookingData.extra.jumpersNumber
    };

    const notIncludedItems = bookingItems.filter(bookingItem => !bookingItem.isIncluded);
    const mappedBookingItems = notIncludedItems.map(item => {
      return {
        item_id: item.productId,
        item_name: item.parentName,
        item_brand: 'addon',
        item_variant: item.name,
        price: item.cost,
        quantity: item.quantity
      };
    });

    const items = [packageItem, ...mappedBookingItems];

    const newTransactionId =
      Math.floor(Math.random() * 10000000000)
        .toFixed(0)
        .padStart(11, '0') +
      '-' +
      Date.now();

    if (venueInfo.name)
      window.dataLayer.push({
        event: 'roller-api-purchase',
        venue_name: venueInfo.name,
        transaction_id: newTransactionId,
        value: bookingData.extra.prices?.total,
        tax: bookingData.extra.prices?.tax || 0,
        shipping: 0,
        currency: 'USD',
        coupon,
        items
      });
  };

  const waiverLink = bookingData.extra.rollerVenueName
    ? `https://waiver.haveablast.roller.app/${bookingData.extra.rollerVenueName}/`
    : `https://www.skyzone.com/#waiver-popup`;

  const invitationLink = bookingData.extra.parkNameUrl
    ? `https://www.skyzone.com/${bookingData.extra.parkNameUrl}/parties/invitations/`
    : `https://www.skyzone.com`;

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 250);

    trackConfirmation();
  }, []);

  return (
    <div className="confirmation-page">
      <div className="container-sm step-container__header">
        <img
          alt="confetti"
          className="confirmation-page__confetti"
          src="/images/confetti.png"
          width={161}
          height={180}
        />
        <h1 className="step-container__heading">CONFIRMED!</h1>
        <p className="step-container__description">
          Great news, {bookingData.customer.firstName} - You are all set for
        </p>
        <h4 className="confirmation-page__goh-name">{partyNameEl}</h4>
        <p className="step-container__description">
          on {formatDate(bookingData.extra.bookingDate)} at{' '}
          {formatTime(bookingData.extra.startTime)} at our {venueInfo.name} location!
        </p>
      </div>
      <div className="container confirmation-page__main-message">
        <h3>Check your inbox!</h3>
        <p>
          To ensure you and your guests have the best possible experience, our party specialists
          have emailed you a unique party link and next steps. Use your unique party link anytime to
          make changes to your party details or reservation. And hey, if you have some spare
          adulting time, you can get started on the next steps below.
        </p>
        <br />
        <p>We can't wait to party with you!</p>
      </div>
      <div className="confirmation-page__party-planning-reminders">
        <h2 className="title">Party Planning Reminders</h2>
        <div className="cards-wrapper">
          <div className="confirmation-page__reminder-card">
            <div className="reminder-card__image-wrapper">
              <img
                src="/images/create-invitations.png"
                width="360"
                height="203"
                alt="create-invitations"
                loading="lazy"
              />
            </div>
            <div className="reminder-card__text-wrapper">
              <h3 className="reminder-card__card-title">SEND OUT YOUR INVITATIONS</h3>
              <p className="reminder-card__card-description">
                Create a custom digital invitation and download to send to your invitees! Be sure to
                include the check-in time for your Guests. Digital Invitations can be printed at
                home or saved as a PDF document to be shared with friends and family as an email
                attachment.
              </p>
            </div>
            <a className="reminder-card__card-button" href={invitationLink} target="_blank">
              <span>Create Your Invitations</span>
              <i>
                <Svg content="chevron-right" />
              </i>
            </a>
          </div>
          <div className="confirmation-page__reminder-card">
            <div className="reminder-card__image-wrapper">
              <img
                src="/images/fill-out-waivers.png"
                width="360"
                height="203"
                alt="create-invitations"
                loading="lazy"
              />
            </div>
            <div className="reminder-card__text-wrapper">
              <h3 className="reminder-card__card-title">COMPLETE YOUR LIABILITY WAIVER(S)</h3>
              <p className="reminder-card__card-description">
                Make sure the waivers are completed before your party date to ensure a quick and
                smooth party check-in. Remind all guests of the party that jumpers must have a
                completed waiver by their own parent/legal guardian.
              </p>
            </div>
            <a className="reminder-card__card-button" href={waiverLink} target="_blank">
              <span>FILL OUT WAIVERS</span>
              <i>
                <Svg content="chevron-right" />
              </i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationPage;
