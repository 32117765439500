import React from 'react';

import Svg from '../components/Svg';

function FixedIconsContainer() {
  return (
    <section className="fixed-icons-container">
      <a className="fixed-icons-container__button" href="#contact-help" aria-label="Contact Help">
        <Svg content="interrogation" />
      </a>
    </section>
  );
}

export default FixedIconsContainer;
